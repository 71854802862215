import React, {useState, useEffect, useRef} from 'react';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import {
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails, FormControlLabel, Checkbox, CircularProgress, Box, Radio,
} from '@mui/material';
import {
    fetchXmlTotalPayoutReportData,
    fetchXmlSspCompanies,
    getXmlPayoutCsvTemplate,
    fetchXmlStatisticFieldsTotalPayout,
    fetchXmlDataFieldsTotalPayout,
} from '../../../../../API/XmlFinancialTools';
import MonthPickerComponent from '../../../../../components/date/MonthPickerComponent';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import SelectMulti from '../../../../../components/select/SelectMulti';
import {fetchPubManagers} from '../../../../../API/AdminUserControl';
import UploadFileDialog from '../components/UploadFileDialog';
import CreateRowDialog from '../components/CreateRowDialog';
import useApi from '../../../../../hooks/useApi';
import Picker from 'react-month-picker'
import {toast} from 'react-toastify';
import Cookies from 'js-cookie';
import axios from "axios";

function TotalPayoutOptionsForm({onSubmit}) {

    const {data: statistics, loading: loadingStats} = useApi(fetchXmlStatisticFieldsTotalPayout);
    const {data: templateLink, loading: loadingTemplateLink} = useApi(getXmlPayoutCsvTemplate);
    const {data: data, loading: loadingData} = useApi(fetchXmlDataFieldsTotalPayout);
    const {data: sspCompany, loading: loadingCompany} = useApi(fetchXmlSspCompanies);
    const {data: managers, loading: loadingManagers} = useApi(fetchPubManagers);

    const [showPublisher, setShowPublisher] = useState(false);
    const [showManager, setShowManager] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);
    const [showStatus, setShowStatus] = useState(false);

    const [selectedPublisher, setSelectedPublisher] = useState('');
    const [selectedManager, setSelectedManager] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');

    function getPreviousMonthString() {
        const currentDate = new Date();
        const previousMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
        const year = previousMonthDate.getFullYear();
        const month = (previousMonthDate.getMonth() + 1).toString().padStart(2, '0');
        return `${year}-${month}`;
    }

    const [status, setStatus] = useState([
        {name: 'New', id: 'new'},
        {name: 'Approved', id: 'approved'}
    ]);
    const handleCloseCreate = () => setOpenCreate(false);
    const handleClickOpenCreate = () => setOpenCreate(true);
    const handleCloseUpload = () => setOpenUpload(false);
    const handleClickOpenUpload = () => setOpenUpload(true);

    const [options, setOptions] = useState({
        stats: [],
        month: null,
        pub_manager: '',
        status: '',
        publisher: '',
    });

    const [month, setMonth] = useState(null);
    const handleMonthChange = (selectedMonth) => {
        setMonth(selectedMonth);
    };

    const [showCalendar, setShowCalendar] = useState(false);


    const dropDownRef = useRef(null);
    const pickerRef = useRef(null);

    const handleCheckboxChange = (event) => {
        const {name} = event.target;
        setOptions({...options, fields: name});
    };

    useEffect(() => {
        const storedValue = Cookies.get('XMLPayout');
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);
            setOptions(parsedValue);
        }

    }, []);

    useEffect(() => {
        setOptions({...options, month: month});
    }, [month]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const element = document.querySelector('.unique-month-picker-class')
            if (element && !element.contains(event.target)) {
                setShowCalendar(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const toggleCalendar = () => {
        if (showCalendar === false) {
            setShowCalendar(true);
        } else {
            setShowCalendar(false);
        }
    };

    const handleCheckboxChangeStat = (event) => {
        const {name, checked} = event.target;
        let stats = [...options.stats];

        if (checked) {
            stats.push(name);
        } else {
            stats = stats.filter((satat) => satat !== name);
        }

        setOptions({...options, stats});
    };

    const handleSubmit = async (event) => {
        onSubmit(options);
    };

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            pub_manager: [selectedManager],
        }));
    }, [selectedManager]);

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            status: [selectedStatus],
        }));
    }, [selectedStatus]);

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            publisher: [selectedPublisher],
        }));
    }, [selectedPublisher]);


    return (
        <Grid container spacing={2} sx={{width: "100%", marginLeft: '0px',}}>
            <Accordion sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: '#f0f3f5', width: "100%"}}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{width: "100%"}}>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12} sx={{width: "100%"}}>
                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>

                                <Grid item xs={12} md={12}>
                                    {Object.keys(statistics).length != 0 ? (<strong>Statistic</strong>) : ''}
                                </Grid>
                                {statistics.map((statistic, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={options.stats.includes(statistic.key)}
                                                onChange={handleCheckboxChangeStat}
                                                name={statistic.key}
                                            />
                                        }
                                        label={statistic.value}
                                    />
                                ))}

                            </Grid>

                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>

                                <Grid item xs={12} md={12}>
                                    {Object.keys(data).length != 0 ? (<strong>Data</strong>) : ''}
                                </Grid>
                                {data.map((statistic, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={options.stats.includes(statistic.key)}
                                                onChange={handleCheckboxChangeStat}
                                                name={statistic.key}
                                            />
                                        }
                                        label={statistic.value}
                                    />
                                ))}

                            </Grid>

                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>
                                <Grid item xs={12} md={12}>
                                    <strong>Filters</strong>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showStatus}
                                                    onChange={() => setShowStatus(!showStatus)}
                                                    name="Status"
                                                />
                                            }
                                            label="Status"
                                        />

                                        {Object.keys(sspCompany).length != 0 ? (<FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showPublisher}
                                                    onChange={() => setShowPublisher(!showPublisher)}
                                                    name="Publisher"
                                                />
                                            }
                                            label="Publisher"
                                        />) : ''}


                                        {Object.keys(managers).length != 0 ? (<FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showManager}
                                                    onChange={() => setShowManager(!showManager)}
                                                    name="pub_manager"
                                                />
                                            }
                                            label="Publisher Managers"
                                        />) : ''}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {showStatus && (
                                            <SelectMulti
                                                label="Status"
                                                items={status}
                                                selectedItems={selectedStatus}
                                                onChange={(event) => setSelectedStatus(event.target.value)}
                                                itemLabel="name"
                                                itemKey="id"
                                                itemValue="id"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {showPublisher && (
                                            <SelectMulti
                                                label="Publisher"
                                                items={sspCompany}
                                                selectedItems={selectedPublisher}
                                                onChange={(event) => setSelectedPublisher(event.target.value)}
                                                itemLabel="value.name"
                                                itemKey="value.id"
                                                itemValue="value.id"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {showManager && (
                                            <SelectMulti
                                                label="Publisher Managers"
                                                items={managers}
                                                selectedItems={selectedManager}
                                                onChange={(event) => setSelectedManager(event.target.value)}
                                                itemLabel="value.name"
                                                itemKey="value.id"
                                                itemValue="value.id"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Grid item xs={6} md={6}>
                <Button
                    size={"large"}
                    startIcon={<BackupTableOutlinedIcon fontSize="small"/>}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{marginLeft: '-15px'}}
                >
                    Generate Report
                </Button>

                <Button
                    size={"large"}
                    startIcon={<PostAddOutlinedIcon/>}
                    onClick={handleClickOpenCreate}
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{marginLeft: '15px'}}
                >
                    Create
                </Button>

                <Button
                    size={"large"}
                    startIcon={<UploadFileOutlinedIcon/>}
                    onClick={handleClickOpenUpload}
                    type="submit"
                    variant="outlined"
                    sx={{marginLeft: '15px'}}
                >
                    Upload CSV
                </Button>
            </Grid>

            <Grid item xs={6} md={6}>
                <div>
                    <div
                        ref={dropDownRef}
                        style={{
                            userSelect: 'none',
                            borderRadius: '5px',
                            textAlign: "center",
                            backgroundColor: '#f0f3f5',
                            padding: '10px',
                            cursor: 'pointer',
                            float: 'right',
                            border: '1px solid #dddfe1',
                        }}
                        onClick={toggleCalendar}
                    >
                        <div>
                            Choose Month
                        </div>
                        <div>
                            <MonthPickerComponent onMonthChange={handleMonthChange} initialMonthYear={getPreviousMonthString()}/>
                        </div>
                    </div>
                </div>
            </Grid>

            <CreateRowDialog
                open={openCreate}
                handleClose={handleCloseCreate}
                ssp={sspCompany}
                onSuccess={handleSubmit}
            />

            <UploadFileDialog
                open={openUpload}
                handleClose={handleCloseUpload}
                templateLink={templateLink}
                onSuccess={handleSubmit}
            />
        </Grid>
    );
}

export default TotalPayoutOptionsForm;
