import React, {useEffect, useState} from "react";
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import FinancialToolsPageLayout from "../pages/XML/FinancialTools/FinancialToolsPageLayout";
import XmlRevenueReport from "../pages/XML/FinancialReports/RevenueReport/XmlRevenueReport";
import XmlPayoutReport from "../pages/XML/FinancialReports/PayoutReport/XmlPayoutReport";
import ExternalPayout from "../pages/XML/FinancialTools/ExternalPayout/ExternalPayout";
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';
import DailyReportLayout from "../pages/XML/Reports/DailyReport/DailyReportLayout";
import TotalRevenue from "../pages/XML/FinancialTools/TotalRevenue/TotalRevenue";
import XmlTotalPayout from "../pages/XML/FinancialTools/TotalPayout/TotalPayout";
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import PubFeedBlockReport from "../pages/XML/AdkCompanies/PubFeedBlockReport";
import RemFeedBlockReport from "../pages/XML/AdkCompanies/RemFeedBlockReport";
import PowerInputOutlinedIcon from '@mui/icons-material/PowerInputOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import DailyReport from "../pages/XML/Reports/DailyReport/DailyReport";
import SmartHubPageLayout from "../pages/SmartHub/SmartHubPageLayout";
import AdmyCompanies from "../pages/XML/AdmyCompanies/AdmyCompanies";
import AdkCompanies from "../pages/XML/AdkCompanies/AdkCompanies";
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import Advertisers from "../pages/XML/Database/Advertisers";
import Publishers from "../pages/XML/Database/Publishers";
import StorageIcon from '@mui/icons-material/Storage';
import HomePage from "../pages/home/HomePage";
import Externals from "../pages/XML/Database/Externals";
import {RouteType} from "./config";
import Cookies from 'js-cookie';

import {useSelector} from "react-redux";

const xmlRoutes: RouteType[] = [
    {
        path: "/XML",
        element: <HomePage />,
        state: "Common",
        sidebarProps: {
            displayText: "Common",
            icon: <DeveloperBoardOutlinedIcon />
        },
        child: [
            {
                path: "/XML",
                element: <HomePage />,
                state: "Dashboard",
                sidebarProps: {
                    displayText: "Dashboard",
                    icon: <PowerInputOutlinedIcon fontSize="small" />
                },
            },
        ],
    },
    {
        path: "/AdKernel",
        element: <SmartHubPageLayout />,
        state: "AdKernel",
        sidebarProps: {
            displayText: "AdKernel",
            icon: <AppsOutlinedIcon />
        },
        child: [
            {
                path: "/AdKernel/AdKernel-Companies",
                element: <AdkCompanies />,
                state: "AdKernel-companies",
                sidebarProps: {
                    displayText: "AdKernel Companies",
                    icon: <PowerInputOutlinedIcon fontSize="small" />
                },
            },
            {
                path: "/AdKernel/Blocking-Feeds-SubIds",
                element: <PubFeedBlockReport />,
                state: "Publisher-Feeds-SubIDs-Blocking",
                sidebarProps: {
                    displayText: "Blocking Feeds SubIds",
                    icon: <PowerInputOutlinedIcon fontSize="small" />
                },
            },
            {
                path: "/AdKernel/Blocking-Remote-Feeds-SubIds",
                element: <RemFeedBlockReport />,
                state: "Publisher-Remote-Feeds-SubIDs-Blocking",
                sidebarProps: {
                    displayText: "Blocking Remote SubIds",
                    icon: <PowerInputOutlinedIcon fontSize="small" />
                },
            },
        ],
    },
    {
        path: "/ADMY",
        element: <SmartHubPageLayout />,
        state: "ADMY",
        sidebarProps: {
            displayText: "ADMY",
            icon: <BackupTableOutlinedIcon />
        },
        child: [
            {
                path: "/ADMY/ADMY-Companies",
                element: <AdmyCompanies />,
                state: "ADMY-companies",
                sidebarProps: {
                    displayText: "ADMY Companies",
                    icon: <PowerInputOutlinedIcon fontSize="small" />
                },
            },
        ],
    },
    {
        path: "/XML-Database",
        element: <SmartHubPageLayout />,
        state: "XML-Database",
        sidebarProps: {
            displayText: "Database",
            icon: <StorageIcon />
        },
        child: [
            {
                path: "/XML-Database/Publishers",
                element: <Publishers />,
                state: "Publishers",
                sidebarProps: {
                    displayText: "Publishers",
                    icon: <PowerInputOutlinedIcon fontSize="small" />
                },
            },
            {
                path: "/XML-Database/Advertisers",
                element: <Advertisers />,
                state: "Advertisers",
                sidebarProps: {
                    displayText: "Advertisers",
                    icon: <PowerInputOutlinedIcon fontSize="small" />
                },
            },
            ...(Cookies.get('user_admin') === '1' || Cookies.get('user_manager') === '1' || Cookies.get('user_pub_manager') === '1'
                ? [
                    {
                        path: "/XML-Database/Externals",
                        element: <Externals/>,
                        state: "Database.Externals",
                        sidebarProps: {
                            displayText: "External Services",
                            icon: <PowerInputOutlinedIcon fontSize="small"/>
                        },
                    },
                ] : []),
        ],
    },
    {
        path: "/XML-Reports",
        element: <DailyReportLayout />,
        state: "XML-Reports",
        sidebarProps: {
            displayText: "Reports",
            icon: <InsertChartOutlinedRoundedIcon />
        },
        child: [
            {
                path: "/XML-Reports/DailyReport",
                element: <DailyReport />,
                state: "DailyReport",
                sidebarProps: {
                    displayText: "DailyReport",
                    icon: <PowerInputOutlinedIcon fontSize="small" />
                },
            },
        ],
    },
    {
        path: "/XML-FinancialTools",
        element: <FinancialToolsPageLayout/>,
        state: "XML.FinancialTools",
        sidebarProps: {
            displayText: "Financial Tools",
            icon: <RequestQuoteOutlinedIcon/>
        },
        child: [
            ...(Cookies.get('user_admin') === '1' || Cookies.get('user_manager') === '1' || Cookies.get('fin_manager') === '1'
                ? [
                    {
                        path: "/XML-FinancialTools/Total-Revenue",
                        element: <TotalRevenue/>,
                        state: "XML.FinancialTools.TotalRevenue",
                        sidebarProps: {
                            displayText: "Total Revenue",
                            icon: <PowerInputOutlinedIcon fontSize="small"/>
                        },
                    }
                ] : []),
            ...(Cookies.get('user_admin') === '1' || Cookies.get('user_manager') === '1' || Cookies.get('user_pub_manager') === '1' || Cookies.get('fin_manager') === '1'
                ? [
                    {
                        path: "/XML-FinancialTools/Total-Payout",
                        element: <XmlTotalPayout/>,
                        state: "XML.FinancialTools.TotalPayout",
                        sidebarProps: {
                            displayText: "Total Payout",
                            icon: <PowerInputOutlinedIcon fontSize="small"/>
                        },
                    }
                ] : []),
            ...(Cookies.get('user_admin') === '1' || Cookies.get('user_manager') === '1' || Cookies.get('user_pub_manager') === '1' || Cookies.get('fin_manager') === '1'
                ? [
                    {
                        path: "/XML-FinancialTools/External-Payout",
                        element: <ExternalPayout/>,
                        state: "FinancialTools.ExternalPayout",
                        sidebarProps: {
                            displayText: "External Payout",
                            icon: <PowerInputOutlinedIcon fontSize="small"/>
                        },
                    }
                ] : []),
        ]
    },
    ...( Cookies.get('fin_manager') === '1' || Cookies.get('user_admin') === '1'
        ? [
            {
                path: "/XML-FinancialReports",
                element: <FinancialToolsPageLayout/>,
                state: "XML-FinancialReports",
                sidebarProps: {
                    displayText: "Financial Reports",
                    icon: <SummarizeOutlinedIcon/>
                },
                child: [
                    {
                        path: "/XML-FinancialReports/XML-Revenue-Report",
                        element: <XmlRevenueReport/>,
                        state: "FinancialReports.XmlRevenueReport",
                        sidebarProps: {
                            displayText: "XML Revenue Report",
                            icon: <PowerInputOutlinedIcon fontSize="small"/>
                        },
                    },
                    {
                        path: "/XML-FinancialReports/XML-Payout-Report",
                        element: <XmlPayoutReport/>,
                        state: "FinancialReports.XmlPayoutReport",
                        sidebarProps: {
                            displayText: "XML Payout Report",
                            icon: <PowerInputOutlinedIcon fontSize="small"/>
                        },
                    },
                ],
            },
        ] : []),
];
export default xmlRoutes;

