import React, {useEffect, useState} from 'react';
import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Alert,
    AlertTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Box,
    Typography
} from '@mui/material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import {sendJsonToApi} from '../../../../../API/apiHelper';
import {toast} from 'react-toastify';
import axios from "axios";

function ApproveRowDialog({
                             open,
                             handleClose,
                             editRow,
                             onApproveSuccess,
                         }) {

    const [endpoint, setEndpoint] = useState('/api/financialtools/xml/totalrevenue/profoma');
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const sendToServer = async () => {
        try {
            setLoading(true);
            const userId = localStorage.getItem('user_id');
            const formData = new FormData();

            if (editRow.id) {
                formData.append('id', editRow.id);
                formData.append('user_id', userId);
                formData.append('period', editRow.period);
                formData.append('advertiser', editRow.advertiserOriginal);
                formData.append('total', editRow.final_revenue);

                const response = await axios.post(`${BASE_URL}${endpoint}`, formData, {
                    headers: {'Content-Type': 'multipart/form-data'},
                });

                if (response.data.success) {
                    toast.success(response.data.message);
                    setLoading(false);
                    handleClose();
                    onApproveSuccess();
                } else {
                    setLoading(false);
                    toast.error('Error: ' + response.data.message);
                }
            } else {
                setLoading(false);
                toast.error('System error: This row without ID.');
            }
        } catch (error) {
            toast.error(`An error occurred while sending data: ${error.message}`);
            console.error('An error occurred while sending data:', error);
        }
    };


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>{"Confirm Numbers for " + editRow.advertiserDisplay}</DialogTitle>
            <DialogContent>
                {loading ? (
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
                                <CircularProgress size={150}/>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Box>
                        <Alert severity="success" variant="outlined" icon={false} sx={{ mb: 3 }}>
                            <Typography variant="h6">
                                <MonetizationOnOutlinedIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Final Revenue: ${editRow.final_revenue}
                            </Typography>
                        </Alert>
                        <Alert variant="outlined" color="warning" severity="warning" sx={{ mb: 3 }}>
                            After confirmation, numbers will be sent to Hyper.
                        </Alert>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    {!loading && (
                        <Button onClick={sendToServer}  color="success">
                            Approve
                        </Button>
                    )}
            </DialogActions>
        </Dialog>
    );
}

export default ApproveRowDialog;