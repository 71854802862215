import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Alert,
    AlertTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress
} from '@mui/material';
import SelectMulti from '../../components/select/SelectMulti';

function CreateExternalCompanyDialog({
                                 open,
                                 handleClose,
                                 editCompany,
                                 handleInputCreateChange,
                                 handleSave,
                                 users,
                             }) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create Company</DialogTitle>
            <DialogContent>
                <Alert variant="outlined" severity="info" color="info" sx={{marginBottom: "20px"}}>
                    Please complete this form to create a new company profile<br/>
                </Alert>

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Company Name"
                    type="text"
                    fullWidth
                    name="name"
                    value={editCompany.name}
                    onChange={handleInputCreateChange}
                />

                <FormControl fullWidth margin="dense">
                    <InputLabel id="manager-label">Manager</InputLabel>
                    <Select
                        labelId="manager-label"
                        id="manager_id"
                        name="manager_id"
                        value={editCompany.manager_id}
                        onChange={handleInputCreateChange}
                        label="Manager"
                    >
                        {users.map((user) => (
                            <MenuItem key={user.value.id} value={user.value.id}>
                                {user.value.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    margin="dense"
                    id="name"
                    label="Hyper ID"
                    type="text"
                    fullWidth
                    name="hyper_id"
                    value={editCompany.hyper}
                    onChange={handleInputCreateChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateExternalCompanyDialog;