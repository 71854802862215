import {fetchDataFromApi, fetchStringFromApi} from './apiHelper';

export const fetchXmlStatisticFieldsTotalRevenue = () => fetchDataFromApi('post', '/api/financialtools/xml/totalrevenue/statistics');
export const fetchXmlTotalRevenueReportData = () => fetchDataFromApi('post', '/api/financialtools/xml/totalrevenue/report');
export const fetchXmlDataFieldsTotalRevenue = () => fetchDataFromApi('post', '/api/financialtools/xml/totalrevenue/data');
export const getXmlCsvTemplate = () => fetchStringFromApi('post', '/api/financialtools/xml/totalrevenue/template');
export const fetchXmlDspCompanies = () => fetchDataFromApi('post', '/api/financialtools/xml/totalrevenue/dsp');
export const fetchXmlSspCompanies = () => fetchDataFromApi('post', '/api/financialtools/xml/totalpayout/ssp');

export const fetchXmlStatisticFieldsTotalPayout = () => fetchDataFromApi('post', '/api/financialtools/xml/totalpayout/statistics');
export const fetchXmlTotalPayoutReportData = () => fetchDataFromApi('post', '/api/financialtools/xml/totalpayout/report');
export const fetchXmlDataFieldsTotalPayout = () => fetchDataFromApi('post', '/api/financialtools/xml/totalpayout/data');
export const getXmlPayoutCsvTemplate = () => fetchStringFromApi('post', '/api/financialtools/xml/totalpayout/template');

export const fetchExternalCompanies = () => fetchDataFromApi('post', '/api/financialtools/external-payout/ssp');

export const fetchExternalReportData = () => fetchDataFromApi('post', '/api/financialtools/external-payout/report');

export const fetchStatisticFieldsExternalPayout = () => fetchDataFromApi('post', '/api/financialtools/external-payout/statistics');
export const fetchDataFieldsExternalPayout = () => fetchDataFromApi('post', '/api/financialtools/external-payout/data');