import React, {useState, useEffect} from 'react';
import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    CircularProgress,
    Box,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { toast } from 'react-toastify';
import 'react-date-range/dist/styles.css';
import useApi from '../../../../hooks/useApi';
import StatisticsCheckbox from '../../../../components/date/StatisticsCheckbox';
import SelectMulti from '../../../../components/select/SelectMulti';
import 'react-date-range/dist/theme/default.css';
import {sendDataToApi} from '../../../../API/apiHelper';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import {fetchAdmyAdvertisers, fetchAdmyPublishers} from '../../../../API/DatabaseReport';
import {fetchAdmyStatisticFields} from '../../../../API/AdmyCompaniesReport';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import CreateCompanyDialog from './CreateCompanyDialog';

function AdmyCompaniesOptionsForm({ onSubmit }) {
    const {data: statistics, loading: loadingStats} = useApi(fetchAdmyStatisticFields);
    const {data: admyAdvertisers, loading: loadingAdmyAdvertisers} = useApi(fetchAdmyAdvertisers);
    const {data: admyPublishers, loading: loadingAdmyPublishers} = useApi(fetchAdmyPublishers);
    const [loading, setLoading] = useState(false);
    const [openEditCompany, setOpenEditCompany] = useState(false);
    const [showCompanies, setShowCompanies] = useState(false);
    const [selectedCompaniesFilter, setSelectedCompaniesFilter] = useState([]);

    const [allAdmyCompanies, setAllAdmyCompanies] = useState([]);

    const [options, setOptions] = useState({
        stats: [],
        companies: [],
    });

    const [editCompany, setEditCompany] = useState([]);

    const handleSubmit = async (event) => {
        onSubmit(options);
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setEditCompany({...editCompany, [name]: value});
    };

    const handleSave = () => {
        const userId = localStorage.getItem('user_id');

        const updatedCompany = {
            ...editCompany,
            ChangedBy: userId
        };

        const response = sendDataToApi('post', '/api/xml/admy-companies/create', updatedCompany, false, (data) => {
            if(data.data === true){
                toast.success('The Company has been created!');
                setEditCompany([]);
                handleSubmit();
                handleClose();
            }else{
                toast.error(`The Company has not created!`);
                toast.error(data.data.error);
            }
        });

    };

    const handleOpenEditCompany = () => {
        setOpenEditCompany(true);
    };

    const handleClose = () => {
        setOpenEditCompany(false);
    };

    const handleCheckboxChangeStat = (event) => {
        const {name, checked} = event.target;
        let stats = [...options.stats];

        if (checked) {
            stats.push(name);
        } else {
            stats = stats.filter((satat) => satat !== name);
        }

        setOptions({...options, stats});
    };

    useEffect(() => {
        if (admyAdvertisers && admyPublishers) {
            const combinedCompanies = [...admyAdvertisers, ...admyPublishers];
            setAllAdmyCompanies(combinedCompanies);
        }
    }, [admyAdvertisers, admyPublishers]);

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            companies: selectedCompaniesFilter,
        }));
    }, [selectedCompaniesFilter]);

    return (
        <Grid container spacing={2} sx={{ width: "100%", marginLeft: '0px', }}>
            <Accordion sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: '#f0f3f5', width: "100%"}}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{width: "100%"}}>
                    <Grid item xs={12} md={12} sx={{width: "100%"}}>
                        <form>
                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '0px 0 25px auto'}}>
                                <Grid item xs={12} md={12}>
                                    {Object.keys(statistics).length != 0 ? (<strong>Data</strong>) : ''}
                                </Grid>
                                {statistics?.map((statistic) => (
                                    <StatisticsCheckbox
                                        key={statistic.key}
                                        statistic={statistic}
                                        checked={options.stats.includes(statistic.key)}
                                        onChange={handleCheckboxChangeStat}
                                    />
                                ))}
                            </Grid>

                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>
                                <Grid item xs={12} md={12}>
                                    <strong>Filters</strong>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        {Object.keys(allAdmyCompanies).length != 0 ? (<FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showCompanies}
                                                    onChange={() => setShowCompanies(!showCompanies)}
                                                    name="companies"
                                                />
                                            }
                                            label="Company"
                                        />) : ''}
                                    </Grid>

                                    <Grid item xs={6}>
                                        {showCompanies && (
                                            <SelectMulti
                                                label="Company"
                                                items={allAdmyCompanies}
                                                selectedItems={selectedCompaniesFilter}
                                                onChange={(event) => setSelectedCompaniesFilter(event.target.value)}
                                                itemLabel="value.name"
                                                itemKey="value.id"
                                                itemValue="value.id"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Grid item xs={6} md={6}>
                <Button
                    size={"large"}
                    startIcon={<BackupTableOutlinedIcon fontSize="small"  />}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginLeft: '-15px', marginBottom: '15px' }}
                >
                    Generate Report
                </Button>
                <Button
                    size={"large"}
                    startIcon={<PlaylistAddIcon fontSize="small"/>}
                    onClick={handleOpenEditCompany}
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{ marginLeft: '15px', marginBottom: '15px' }}
                >
                    Create
                </Button>
            </Grid>
            {loading &&
                <Grid item xs={12} md={12}>
                    <Box justifyContent="center" textAlign={"center"} alignItems="center">
                        <CircularProgress size={200}/>
                    </Box>
                </Grid>}

            <CreateCompanyDialog
                open={openEditCompany}
                handleClose={handleClose}
                editCompany={editCompany}
                handleInputCreateChange={handleInputChange}
                handleSave={handleSave}
            />
        </Grid>
    );
}

export default AdmyCompaniesOptionsForm;
