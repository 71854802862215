import React, { useEffect, useState, useRef } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/system';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-row:nth-child(even)': {
        backgroundColor: '#eaf0f5',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal !important',
        wordWrap: 'break-word',
    },
    '& .total-row': {
        backgroundColor: '#b8ccda',
        fontWeight: 'bold',
    },
}));

function processData(data) {
    if (!data || !Array.isArray(data) || data.length === 0) return [];

    return data.map((row, index) => {
        const rowData = row.value || row;
        return {
            id: rowData.id || index,
            campaign_name: rowData.campaign_name || 'Unknown',
            campaign_id: rowData.campaign_id || 'N/A',
            date: rowData.date || 'N/A',
            cost:
                rowData.cost !== undefined
                    ? Number(rowData.cost).toFixed(2)
                    : '0.00',
            partner: rowData.partner || 'N/A',
            company_name: rowData.company_name || null,
            admy_cost:
                rowData.admy_cost !== undefined
                    ? Number(rowData.admy_cost).toFixed(2)
                    : undefined,
            adk_cost:
                rowData.adk_cost !== undefined
                    ? Number(rowData.adk_cost).toFixed(2)
                    : undefined,
            partner_cost:
                rowData.partner_cost !== undefined
                    ? Number(rowData.partner_cost).toFixed(2)
                    : undefined,
        };
    });
}

const DailyReportGrid = ({ data }) => {
    const gridRef = useRef(null);
    const [rows, setRows] = useState([]);

    const availableFields = new Set();
    if (data && Array.isArray(data)) {
        data.forEach((row) => {
            const rowData = row.value || row;
            Object.keys(rowData).forEach((key) => availableFields.add(key));
        });
    }

    useEffect(() => {
        const processedData = processData(data);

        if (processedData.length > 0) {
            const totalCost = processedData.reduce(
                (acc, row) => acc + (parseFloat(row.cost) || 0),
                0
            );
            const totalAdmyCost = processedData.reduce(
                (acc, row) => acc + (parseFloat(row.admy_cost) || 0),
                0
            );
            const totalAdkCost = processedData.reduce(
                (acc, row) => acc + (parseFloat(row.adk_cost) || 0),
                0
            );
            const totalPartnerCost = processedData.reduce(
                (acc, row) => acc + (parseFloat(row.partner_cost) || 0),
                0
            );

            processedData.push({
                id: 'total',
                campaign_id: '',
                campaign_name: 'Total',
                date: '',
                cost: totalCost.toFixed(2),
                partner: '',
                company_name: '',
                admy_cost: totalAdmyCost.toFixed(2),
                adk_cost: totalAdkCost.toFixed(2),
                partner_cost: totalPartnerCost.toFixed(2),
            });
        }

        setRows(processedData);
    }, [data]);

    if (!rows.length) return null;

    let columns = [];
    if (availableFields.has('campaign_id')) {
        columns.push({
            field: 'campaign_id',
            headerName: 'Campaign ID',
            flex: 1,
            minWidth: 100,
        });
    }
    if (availableFields.has('campaign_name')) {
        columns.push({
            field: 'campaign_name',
            headerName: 'Campaign Name',
            flex: 2,
            minWidth: 200,
        });
    }
    if (availableFields.has('cost')) {
        columns.push({
            field: 'cost',
            headerName: 'Cost ($)',
            flex: 1,
            minWidth: 100,
        });
    }
    if (availableFields.has('admy_cost')) {
        columns.push({
            field: 'admy_cost',
            headerName: 'ADMY Cost ($)',
            flex: 1,
            minWidth: 100,
        });
    }
    if (availableFields.has('adk_cost')) {
        columns.push({
            field: 'adk_cost',
            headerName: 'AdKernel Cost ($)',
            flex: 1,
            minWidth: 100,
        });
    }
    if (availableFields.has('partner_cost')) {
        columns.push({
            field: 'partner_cost',
            headerName: 'Partner Cost ($)',
            flex: 1,
            minWidth: 100,
        });
    }
    if (availableFields.has('partner')) {
        columns.push({
            field: 'partner',
            headerName: 'Partner',
            flex: 1,
            minWidth: 150,
        });
    }
    if (availableFields.has('company_name')) {
        columns.push({
            field: 'company_name',
            headerName: 'Company Name',
            flex: 1,
            minWidth: 150,
        });
    }
    if (availableFields.has('date')) {
        columns.push({
            field: 'date',
            headerName: 'Date',
            flex: 1,
            minWidth: 150,
        });
    }

    return (
        <div style={{ height: 800, width: '100%' }} ref={gridRef}>
            <StyledDataGrid
                rows={rows}
                columns={columns}
                pageSize={25}
                slots={{
                    toolbar: GridToolbar,
                }}
                disableRowSelectionOnClick
                getRowClassName={(params) =>
                    params.row.id === 'total' ? 'total-row' : ''
                }
            />
        </div>
    );
};

export default DailyReportGrid;
