import React, { useEffect, useState } from 'react';
import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Alert,
    CircularProgress,
    Box,
    Typography
} from '@mui/material';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { toast } from 'react-toastify';
import axios from "axios";

function ApproveRowDialog({ open, handleClose, editRow, onApproveSuccess }) {
    const [endpoint] = useState('/api/financialtools/xml/totalpayout/profoma');
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        setShowMessage(!editRow.hyper_id);
    }, [editRow]);

    const sendToServer = async () => {
        try {
            setLoading(true);
            const userId = localStorage.getItem('user_id');
            const formData = new FormData();

            if (editRow.id) {
                formData.append('id', editRow.id);
                formData.append('user_id', userId);
                formData.append('period', editRow.period);
                formData.append('hyper', editRow.hyper_id);
                formData.append('publisher', editRow.publisherOriginal);
                formData.append('comment', editRow.revision_comment);
                formData.append('total', editRow.final_cost);

                const response = await axios.post(`${BASE_URL}${endpoint}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                if (response.data.success) {
                    toast.success(response.data.message);
                    setLoading(false);
                    handleClose();
                    onApproveSuccess();
                } else {
                    setLoading(false);
                    toast.error('Error: ' + response.data.message);
                }
            } else {
                setLoading(false);
                toast.error('System error: This row without ID.');
            }
        } catch (error) {
            toast.error(`An error occurred while sending data: ${error.message}`);
            console.error('An error occurred while sending data:', error);
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>{"Confirm Revenue for " + editRow.publisherDisplay}</DialogTitle>
            <DialogContent>
                {showMessage ? (
                    <Alert variant="outlined" color="error" severity="error" sx={{ mb: 3 }}>
                        <Typography variant="body1">
                            The publisher <b>doesn't have</b> a Hyper ID. Please <b>add a Hyper ID</b> to the Publisher using the Database
                            tools on the <b>SSP</b> tab.
                        </Typography>
                    </Alert>
                ) : (
                    loading ? (
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
                                    <CircularProgress size={150} />
                                </Box>
                            </Grid>
                        </Grid>
                    ) : (
                        <Box>
                            <Alert severity="success" variant="outlined" icon={false} sx={{ mb: 3 }}>
                                <Typography variant="h6">
                                    <MonetizationOnOutlinedIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Final Cost: ${editRow.final_cost}
                                </Typography>
                            </Alert>
                            <Alert variant="outlined" color="warning" severity="warning" sx={{ mb: 3 }}>
                                After confirmation, numbers will be sent to Hyper.
                            </Alert>
                        </Box>
                    )
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                {!loading && !showMessage && (
                    <Button onClick={sendToServer} color="success">
                        Approve
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default ApproveRowDialog;
