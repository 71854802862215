import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Alert,
    AlertTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress
} from '@mui/material';

function CreateCompanyDialog({
                                 open,
                                 handleClose,
                                 editCompany,
                                 handleInputCreateChange,
                                 handleSave,
                             }) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create an ADMY Company</DialogTitle>
            <DialogContent>
                <Alert variant="outlined" severity="info" color="info" sx={{marginBottom: "20px"}}>
                    Please complete this form to create a new ADMY company profile<br/>
                </Alert>

                <FormControl fullWidth margin="dense">
                    <TextField
                        autoFocus
                        margin="dense"
                        name="name"
                        label="Company name"
                        type="text"
                        value={editCompany.name || ''}
                        onChange={handleInputCreateChange}
                        fullWidth
                    />
                </FormControl>

                <FormControl fullWidth margin="dense">
                    <TextField
                        autoFocus
                        margin="dense"
                        name="short"
                        label="Short name"
                        type="text"
                        value={editCompany.short || ''}
                        onChange={handleInputCreateChange}
                        fullWidth
                    />
                </FormControl>

                <FormControl fullWidth margin="dense">
                    <TextField
                        autoFocus
                        margin="dense"
                        name="api"
                        label="API Key"
                        type="text"
                        value={editCompany.api || ''}
                        onChange={handleInputCreateChange}
                        fullWidth
                    />
                </FormControl>

                <FormControl fullWidth margin="dense">
                    <InputLabel id="status-label">Type</InputLabel>
                    <Select
                        value={editCompany.type || ''}
                        onChange={handleInputCreateChange}
                        name="type"
                        label="Type"
                        fullWidth
                    >
                        <MenuItem value={"publisher"}>Publisher</MenuItem>
                        <MenuItem value={"advertiser"}>Advertiser</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="dense">
                    <InputLabel id="status-label">Compare</InputLabel>
                    <Select
                        value={editCompany.compare || ''}
                        onChange={handleInputCreateChange}
                        name="compare"
                        label="Compare"
                        fullWidth
                    >
                        <MenuItem value={"true"}>Yes</MenuItem>
                        <MenuItem value={"false"}>No</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateCompanyDialog;