import {fetchDataFromApi, fetchStringFromApi} from './apiHelper';

export const fetchStatisticFieldsRevenue = () => fetchDataFromApi('post', '/api/financialreports/revenue/statistics');
export const fetchIntervalsRevenue = () => fetchDataFromApi('post', '/api/financialreports/revenue/intervals');
export const fetchRevenueReportData = () => fetchDataFromApi('post', '/api/financialreports/revenue/report');
export const fetchDataFieldsRevenue = () => fetchDataFromApi('post', '/api/financialreports/revenue/data');


export const fetchStatisticFieldsPayout = () => fetchDataFromApi('post', '/api/financialreports/payout/statistics');
export const fetchIntervalsPayout = () => fetchDataFromApi('post', '/api/financialreports/payout/intervals');
export const fetchPayoutReportData = () => fetchDataFromApi('post', '/api/financialreports/payout/report');
export const fetchDataFieldsPayout = () => fetchDataFromApi('post', '/api/financialreports/payout/data');

export const fetchXmlStatisticFieldsRevenue = () => fetchDataFromApi('post', '/api/financialreports/xml/revenue/statistics');
export const fetchXmlIntervalsRevenue = () => fetchDataFromApi('post', '/api/financialreports/xml/revenue/intervals');
export const fetchXmlRevenueReportData = () => fetchDataFromApi('post', '/api/financialreports/xml/revenue/report');
export const fetchXmlDataFieldsRevenue = () => fetchDataFromApi('post', '/api/financialreports/xml/revenue/data');


export const fetchXmlStatisticFieldsPayout = () => fetchDataFromApi('post', '/api/financialreports/xml/payout/statistics');
export const fetchXmlIntervalsPayout = () => fetchDataFromApi('post', '/api/financialreports/xml/payout/intervals');
export const fetchXmlPayoutReportData = () => fetchDataFromApi('post', '/api/financialreports/xml/payout/report');
export const fetchXmlDataFieldsPayout = () => fetchDataFromApi('post', '/api/financialreports/xml/payout/data');
