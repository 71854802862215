import {fetchDataFromApi} from './apiHelper';

export const fetchDspStatisticFields = () => fetchDataFromApi('post', '/api/database/dsp/statistic');
export const fetchDspComanies = () => fetchDataFromApi('post', '/api/database/dsp/companies');
export const fetchDspSmartHub = () => fetchDataFromApi('post', '/api/database/dsp/smarthub');
export const fetchFreeDspSmartHub = () => fetchDataFromApi('post', '/api/database/dsp/freesmarthub');
export const fetchDspBidscube = () => fetchDataFromApi('post', '/api/database/dsp/bidscube');
export const fetchFreeDspBidscube = () => fetchDataFromApi('post', '/api/database/dsp/freebidscube');

export const fetchSspStatisticFields = () => fetchDataFromApi('post', '/api/database/ssp/statistic');
export const fetchSspComanies = () => fetchDataFromApi('post', '/api/database/ssp/companies');
export const fetchSspSmartHub = () => fetchDataFromApi('post', '/api/database/ssp/smarthub');
export const fetchFreeSspSmartHub = () => fetchDataFromApi('post', '/api/database/ssp/freesmarthub');
export const fetchSspBidscube = () => fetchDataFromApi('post', '/api/database/ssp/bidscube');
export const fetchFreeSspBidscube = () => fetchDataFromApi('post', '/api/database/ssp/freebidscube');

export const fetchFreeAdmyPublishers = () => fetchDataFromApi('post', '/api/database/xml/admy/publishers');
export const fetchFreeAdmyAdvertisers = () => fetchDataFromApi('post', '/api/database/xml/admy/advertisers');

export const fetchAdmyAdvertisers = () => fetchDataFromApi('post', '/api/database/xml/admy/all-advertisers');
export const fetchAdmyPublishers = () => fetchDataFromApi('post', '/api/database/xml/admy/all-publishers');

export const fetchAllXmlAdvertisers = () => fetchDataFromApi('post', '/api/database/xml/all-advertisers');
export const fetchAllXmlPublishers = () => fetchDataFromApi('post', '/api/database/xml/all-publishers');

export const fetchXmlStatisticFields = () => fetchDataFromApi('post', '/api/database/xml/statistic');

export const fetchXmlExternalsStatisticFields = () => fetchDataFromApi('post', '/api/database/xml-external/statistic');

export const fetchAllXmlExternals = () => fetchDataFromApi('post', '/api/database/xml-external/all');

export const fetchFreeAdkPublishers = () => fetchDataFromApi('post', '/api/database/xml/adk/publishers');
export const fetchFreeAdkAdvertisers = () => fetchDataFromApi('post', '/api/database/xml/adk/advertisers');

export const fetchAdkAdvertisers = () => fetchDataFromApi('post', '/api/database/xml/adk/all-advertisers');
export const fetchAdkPublishers = () => fetchDataFromApi('post', '/api/database/xml/adk/all-publishers');