import React, {useEffect, useState, useRef} from 'react';
import {DataGrid, GridToolbar, useGridApiRef} from '@mui/x-data-grid';
import {
    Checkbox,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Alert,
    AlertTitle,
    FormControlLabel,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    OutlinedInput,
    Box,
} from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import {sendDataToApi} from '../../../../../API/apiHelper';
import useApi from '../../../../../hooks/useApi';
import {styled} from '@mui/system';
import Cookies from 'js-cookie';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};

function formatCurrency(value) {
    if (typeof value !== 'undefined' && value !== null) {
        return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    } else {
        return '';
    }
}

function formatPersent(value) {
    if (typeof value !== 'undefined' && value !== null) {
        return value.toFixed(2);
    } else {
        return '';
    }
}

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .MuiDataGrid-row': {
        '&:nth-child(even)': {
            backgroundColor: '#eaf0f5',
        },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },
    '& .negative.win_rate_per, .negative.revenue_per, .negative.fill_rate_per, .negative.profit_per': {
        backgroundColor: 'rgba(255,235,235)',
    },
    '& .positive.win_rate_per, .positive.revenue_per, .positive.fill_rate_per, .positive.profit_per': {
        backgroundColor: 'rgba(235,255,235)',
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal !important',
        wordWrap: 'break-word',
    },
    '& .total-row': {
        backgroundColor: '#f8fff9',
        fontWeight: 'bold',
    },
}));

function processData(data) {
    if (!data || !Array.isArray(data) || data.length === 0) return [];

    const processedData = data.map(row => ({
        id: row.value.id,
        ...row.value,
        advertiserDisplay: row.value.advertiser ? row.value.advertiser.name : '',
        advertiserOriginal: row.value.advertiser ? row.value.advertiser.id : '',
        accManagerDisplay: row.value.account_manager ? row.value.account_manager.name : '',
        accManagerMail: row.value.account_manager ? row.value.account_manager.mail : '',
        accManagerOriginal: row.value.account_manager ? row.value.account_manager.id : '',
        irtb_statusDisplay: row.value.irtb_status ? row.value.irtb_status.charAt(0).toUpperCase() + row.value.irtb_status.slice(1) : '',
    }));

    if (processedData.length > 1) {
        const totalRow = processedData.shift();
        totalRow.total = true;
        processedData.push(totalRow);
    }

    return processedData;
}


const RevenueReportDataGrid = ({data, onRowEdit}) => {
    const gridRef = useRef(null);
    const [rows, setRows] = useState(processData(data));

    useEffect(() => {
        setRows(processData(data));
    }, [data]);

    const advertiser = rows.some(row => 'advertiser' in row);
    const hyper_status = rows.some(row => 'hyper_status' in row);
    const irtb_status = rows.some(row => 'irtb_status' in row);
    const account_manager = rows.some(row => 'account_manager' in row);
    const hyper = rows.some(row => 'hyper' in row);

    const final = rows.some(row => 'final' in row);
    const approved_final = rows.some(row => 'approved_final' in row);
    const period = rows.some(row => 'DATE_FORMAT(period, \'%Y-%m\')' in row);
    const year = rows.some(row => 'DATE_FORMAT(period, \'%Y\')' in row);


    const columns = [];

    if (period) columns.push({field: 'DATE_FORMAT(period, \'%Y-%m\')', headerName: 'Period', width: 80});
    if (year) columns.push({field: 'DATE_FORMAT(period, \'%Y\')', headerName: 'Year', width: 80});
    if (advertiser) columns.push({field: 'advertiserDisplay', headerName: 'Advertiser', width: 200});
    if (hyper) columns.push({field: 'hyper', headerName: 'Hyper ID', width: 100});
    if (account_manager) columns.push({field: 'accManagerDisplay', headerName: 'Manager Name', width: 150});
    if (account_manager) columns.push({field: 'accManagerMail', headerName: 'Manager Mail', width: 150});
    if (hyper_status) columns.push({field: 'hyper_status', headerName: 'Hyper Status', width: 150});
    if (irtb_status) columns.push({field: 'irtb_statusDisplay', headerName: 'iRTB Status', width: 100});

    if (final) columns.push({
        field: 'final',
        headerName: 'Gross Total Final Revenue, $',
        width: 200,
        valueFormatter: (params) => `${formatCurrency(params.value)}`
    });
    if (approved_final) columns.push({
        field: 'approved_final',
        headerName: 'Approved Total Final Revenue, $',
        width: 230,
        valueFormatter: (params) => `${formatCurrency(params.value)}`
    });

    if (data.length === 0) {
        return null;
    } else {

        return (

            <div style={{height: 800, width: '100%'}} ref={gridRef}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={25}
                    getRowClassName={(params) => (params.row.total ? 'total-row' : '')}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    disableRowSelectionOnClick
                />
            </div>
        );
    }
};

export default RevenueReportDataGrid;
