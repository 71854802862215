import { ReactNode } from "react";
import { Route } from "react-router-dom";
import PageWrapper from "../components/layout/PageWrapper";
import appRoutes from "./appRoutes";
import xmlRoutes from "./xmlRoutes";
import { RouteType } from "./config";
import PrivateRoute from "./PrivateRoute";
import NotFound from "../pages/404/Page404";

const allRoutes = [...appRoutes, ...xmlRoutes];

const generateRoute = (routes: RouteType[]): ReactNode => {
    return routes.map((route, index) => (
        route.index ? (
            <Route
                index
                path={route.path}
                element={
                    <PrivateRoute>
                        <PageWrapper state={route.state}>
                            {route.element}
                        </PageWrapper>
                    </PrivateRoute>
                }
                key={index}
            />
        ) : (
            <Route
                path={route.path}
                element={
                    <PrivateRoute>
                        <PageWrapper state={route.child ? undefined : route.state}>
                            {route.element}
                        </PageWrapper>
                    </PrivateRoute>
                }
                key={index}
            >
                {route.child && (
                    generateRoute(route.child)
                )}
            </Route>
        )
    )).concat(
        <Route path="*" element={<PrivateRoute><NotFound/></PrivateRoute>} key="404"/>
    );
};

export const routes: ReactNode = generateRoute(allRoutes);