import { Avatar, Drawer, List, Stack, Toolbar } from "@mui/material";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import { useNavigate } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import { useEffect, useState } from "react";
import { Divider, Chip, ButtonGroup, Button } from "@mui/material";
import appRoutes from "../../routes/appRoutes";
import xmlRoutes from "../../routes/xmlRoutes";
import Cookies from 'js-cookie';

const Sidebar = ({ isOpen, handleDrawerOpen, onMenuItemClick }) => {
    const [openItem, setOpenItem] = useState(null);
    const [activeMenu, setActiveMenu] = useState(() => Cookies.get('activeMenu') || 'RTB');
    const navigate = useNavigate();
    const [showButtons, setShowButtons] =  useState(false);
    const [allowButtons, setAllowButtons] =  useState(false);

    const handleAvatarClick = () => {
        navigate("/");
    };

    const handleOpen = (id) => {
        setOpenItem((prevOpenItem) => (prevOpenItem === id ? null : id));
    };

    const handleSetActiveMenu = (menu) => {
        setActiveMenu(menu);
        Cookies.set('activeMenu', menu);
    };

    useEffect(() => {
        if (!showButtons) {
            setOpenItem(null);
        } else if (!isOpen) {
            setOpenItem(null);
        } else {
            setShowButtons(true);
        }
    }, [isOpen, showButtons]);

    useEffect(() => {
        if (!isOpen) {
            setShowButtons(false);
        }else{
            if(allowButtons){
                if (isOpen) {
                    setShowButtons(true);
                }
            }
        }
    }, [isOpen]);

    useEffect(() => {
        const userProjects = Cookies.get('user_projects');
        const userAdmin = Cookies.get('user_admin') === '1';

        if (userAdmin || (userProjects && userProjects.includes('RTB') && userProjects.includes('XML'))) {
            setShowButtons(true);
            setAllowButtons(true)
        } else if (userProjects) {
            const projects = userProjects.split(',');

            if (projects.includes('RTB') || projects.includes('XML')) {
                setShowButtons(false);
                setActiveMenu(projects.includes('RTB') ? 'RTB' : 'XML');
            } else {
                setShowButtons(false);
                setActiveMenu('RTB');
            }
        } else {
            setShowButtons(false);
            setActiveMenu('RTB');
        }
    }, []);

    const routes = activeMenu === 'RTB' ? appRoutes : xmlRoutes;

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: isOpen ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.minWidth,
                flexShrink: 1,
                "& .MuiDrawer-paper": {
                    width: isOpen ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.minWidth,
                    boxSizing: "border-box",
                    borderRight: "0px",
                    backgroundColor: colorConfigs.sidebar.bg,
                    color: colorConfigs.sidebar.color,
                },
            }}
            onClick={!isOpen ? handleDrawerOpen : undefined}
        >
            <List disablePadding>
                <Toolbar sx={{ marginBottom: "20px" }}>
                    <Stack sx={{ width: "100%" }} direction="row" justifyContent="center">
                        {isOpen ? (
                            <Avatar
                                sx={{
                                    paddingX: "20px",
                                    width: "100%",
                                    height: "100%",
                                    transition: "transform 0.3s ease-in-out",
                                    "&:hover": {
                                        transform: "scale(0.9)",
                                        cursor: "pointer",
                                    },
                                }}
                                src={assets.images.logo}
                                variant="square"
                                onClick={handleAvatarClick}
                            />
                        ) : (
                            <Avatar
                                sx={{
                                    marginLeft: "10px",
                                    width: "200%",
                                    height: "200%",
                                    transition: "transform 0.3s ease-in-out",
                                    "&:hover": {
                                        transform: "scale(0.9)",
                                        cursor: "pointer",
                                    },
                                }}
                                src={assets.images.smallLogo}
                                variant="square"
                                onClick={handleAvatarClick}
                            />
                        )}
                    </Stack>
                </Toolbar>

                <ButtonGroup
                    variant="text"
                    color="inherit"
                    aria-label="Small-sized button group"
                    sx={{
                        margin: "10px",
                        display: showButtons ? "flex" : "none",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        color={'inherit'}
                        overlap="circular"
                        size="small"
                        onClick={() => handleSetActiveMenu('RTB')}
                        variant={"outlined"}
                        sx={{
                            backgroundColor: activeMenu === 'RTB' ? colorConfigs.sidebar.activeBg : 'transparent',
                            '&:hover': {
                                backgroundColor: colorConfigs.sidebar.hoverBg,
                            },
                        }}
                    >
                        iRTB
                    </Button>
                    <Button
                        color={'inherit'}
                        overlap="circular"
                        size="small"
                        onClick={() => handleSetActiveMenu('XML')}
                        variant={"outlined"}
                        sx={{
                            backgroundColor: activeMenu === 'XML' ? colorConfigs.sidebar.activeBg : 'transparent',
                            '&:hover': {
                                backgroundColor: colorConfigs.sidebar.hoverBg,
                            },
                        }}
                    >
                        XML
                    </Button>
                </ButtonGroup>

                {routes.map((route, index) => (
                    <>
                        {route.sidebarProps && route.state === "Admin" && (
                            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                        )}
                        {route.child ? (
                            <SidebarItemCollapse
                                item={route}
                                key={index}
                                onOpenChange={handleOpen}
                                sidebarOpen={isOpen}
                                onMenuItemClick={onMenuItemClick}
                            />
                        ) : (
                            <SidebarItem
                                item={route}
                                key={index}
                                onMenuItemClick={onMenuItemClick}
                            />
                        )}
                    </>
                ))}
            </List>
        </Drawer>
    );
};

export default Sidebar;
