import React, {useState, useEffect, useRef} from 'react';
import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    CircularProgress,
    Box,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    Alert,
    AlertTitle,
    Dialog,
} from '@mui/material';
import StatisticsCheckbox from '../../../../components/date/StatisticsCheckbox';
import SelectMulti from '../../../../components/select/SelectMulti';
import useApi from '../../../../hooks/useApi';
import DateRangeSelector from '../../../../components/date/DateRangeSelector';
import {fetchRemoteStatisticFields} from '../../../../API/PubFeedBlockReport';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import {toast} from 'react-toastify';
import {sendDataToApi} from '../../../../API/apiHelper';

function RemFeedBlockReportOptions({onSubmit}) {
    
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    const [options, setOptions] = useState({
        start_date: yesterday,
        end_date: yesterday,
        stats: [],
    });
    const [loading, setLoading] = useState(false);
    const {data: statistics, loading: loadingStats} = useApi(fetchRemoteStatisticFields);

    const handleCheckboxChangeStat = (event) => {
        const {name, checked} = event.target;
        let stats = [...options.stats];

        if (checked) {
            stats.push(name);
        } else {
            stats = stats.filter((satat) => satat !== name);
        }

        setOptions({...options, stats});
    };

    const handleSubmit = async (event) => {
        onSubmit(options);
    };

    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(options.start_date),
        endDate: new Date(options.start_date),
        key: 'selection',
    });
    const handleDateChange = (event) => {
        const endDate = new Date(event.endDate);
        endDate.setHours(12, 12, 12, 12);
        const endDateISOString = endDate.toISOString().split('T')[0];

        const startDate = new Date(event.startDate);
        startDate.setHours(12, 12, 12, 12);
        const startDateISOString = startDate.toISOString().split('T')[0];

        setSelectedRange((prevSelectedRange) => ({
            ...prevSelectedRange,
            startDate: new Date(startDateISOString),
            endDate: new Date(endDateISOString),
            key: 'selection',
        }));

        if (endDate && startDate) {
            setOptions((prevOptions) => ({
                ...prevOptions,
                start_date: startDateISOString,
                end_date: endDateISOString,
            }));
        }
    };

    return (
        <Grid container spacing={2} sx={{width: "100%", marginLeft: '0px'}}>
            <Accordion sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: '#f0f3f5', width: "100%"}}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{width: "100%"}}>
                    <Grid item xs={12} md={12} sx={{width: "100%"}}>
                        <form>
                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '0px 0 25px auto'}}>
                                <Grid item xs={12} md={12}>
                                    {Object.keys(statistics).length != 0 ? (<strong>Report fields</strong>) : ''}
                                </Grid>
                                {statistics?.map((statistic) => (
                                    <StatisticsCheckbox
                                        key={statistic.key}
                                        statistic={statistic}
                                        checked={options.stats.includes(statistic.key)}
                                        onChange={handleCheckboxChangeStat}
                                    />
                                ))}
                            </Grid>
                        </form>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Grid item xs={6} md={6}>
                <Button
                    size="large"
                    startIcon={<BackupTableOutlinedIcon/>}
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    color="primary"
                    sx={{marginLeft: '-15px'}}
                >
                    Generate Report
                </Button>
            </Grid>

            <Grid item xs={6} md={6}>
                <DateRangeSelector
                    initialRange={{ startDate: yesterday, endDate: yesterday, key: 'selection' }}
                    onRangeChange={handleDateChange}
                />
            </Grid>
        </Grid>
    );
}

export default RemFeedBlockReportOptions;