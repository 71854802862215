import React, { useReducer, useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Alert,
    AlertTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress
} from '@mui/material';
import SelectMulti from '../../../../../components/select/SelectMulti';
import MonthPickerComponent from '../../../../../components/date/MonthPickerComponent';
import {toast} from 'react-toastify';
import axios from "axios";


const initialState = {
    month: '',
    advertiser: '',
    sys_revenue: '',
    fin_revenue: '',
    file_link: '',
    file: null,
    comment: '',
    id: '',
    confirmationOpen: false,
};

function getInitialState(editRow) {
    let periodString = '';
    if (editRow.period) {
        const parts = editRow.period.split('-');
        if (parts.length >= 2) {
            periodString = `${parts[0]}-${parseInt(parts[1], 10)}`;
        }
    }
    return {
        ...initialState,
        month: periodString,
        advertiser: editRow.advertiserOriginal || '',
        sys_revenue: editRow.revenue_from_the_system || '',
        fin_revenue: editRow.final_revenue || '',
        file_link: editRow.file || '',
        comment: editRow.revision_comment || '',
        id: editRow.id || '',
    };
}

function reducer(state, action) {
    switch (action.type) {
        case 'set':
            return { ...state, [action.field]: action.value };
        case 'toggleConfirmation':
            return { ...state, confirmationOpen: !state.confirmationOpen };
        case 'reset':
            return initialState;
        default:
            return state;
    }
}


function EditRowDialog({
                           open,
                           handleClose,
                           dsp,
                           editRow,
                           onSubmitSuccess,
                       }) {
    const [state, dispatch] = useReducer(reducer, getInitialState(editRow));

    useEffect(() => {
        dispatch({ type: 'set', field: 'advertiser', value: editRow.advertiserOriginal });
        dispatch({ type: 'set', field: 'sys_revenue', value: editRow.revenue_from_the_system });
        dispatch({ type: 'set', field: 'fin_revenue', value: editRow.final_revenue });
        dispatch({ type: 'set', field: 'file_link', value: editRow.file });
        dispatch({ type: 'set', field: 'comment', value: editRow.revision_comment });
        dispatch({ type: 'set', field: 'id', value: editRow.id });
        let periodString = '';
        if (editRow.period) {
            const parts = editRow.period.split('-');
            if (parts.length >= 2) {
                periodString = `${parts[0]}-${parseInt(parts[1], 10)}`;
            }
        }
        dispatch({ type: 'set', field: 'month', value: periodString });
    }, [editRow]);


    const [endpoint, setEndpoint] = useState('/api/financialtools/xml/totalrevenue/edit');
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const handleMonthChange = (selectedMonth) => {
        const periodString = `${selectedMonth.year}-${selectedMonth.month}`;
        dispatch({ type: 'set', field: 'month', value: periodString });
    };
    const handleInputChange = (event) => {
        dispatch({ type: 'set', field: event.target.name, value: event.target.value });
    };

    const handleInputFileChange = (event) => {
        dispatch({ type: 'set', field: event.target.name, value: event.target.files[0] });
    };

    const validateFormData = () => {
        const requiredFields = [state.advertiser, state.sys_revenue, state.fin_revenue];
        return requiredFields.every(field => field !== '' || field === 0);
    };

    const sendToServer = async () => {
        if (!validateFormData()) {
            toast.error('Fill required fields first!');
            return;
        }

        const difference = Math.abs(state.sys_revenue - state.fin_revenue);
        const threshold = 0.5 * Math.max(state.sys_revenue, state.fin_revenue);

        if (difference > threshold) {
            dispatch({ type: 'toggleConfirmation' });
            return;
        }

        await submitData();
    };

    const submitData = async () => {
        try {
            const userId = localStorage.getItem('user_id');
            const formData = new FormData();
            const formFields = {
                file: state.file,
                user_id: userId,
                advertiser: state.advertiser,
                sys_revenue: state.sys_revenue,
                fin_revenue: state.fin_revenue,
                file_link: state.file_link,
                month: state.month,
                comment: state.comment,
                id: state.id
            };

            Object.keys(formFields).forEach(key => {
                if (formFields[key]) formData.append(key, formFields[key]);
            });

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${endpoint}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.data.success) {
                toast.success(response.data.message);
                onSubmitSuccess();
                dispatch({ type: 'reset' });
                handleClose();
            } else {
                toast.error('Error: ' + response.data.message);
            }
        } catch (error) {
            toast.error(`An error occurred while sending data: ${error.message}`);
            console.error('An error occurred while sending data:', error);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit {editRow.publisherDisplay}</DialogTitle>
            <DialogContent>
                <Alert variant="outlined" severity="info" color="info" sx={{marginBottom: "20px"}}>
                    Please provide the new values in this form for updating.<br/>
                </Alert>

                <div
                    style={{
                        userSelect: 'none',
                        borderRadius: '5px',
                        textAlign: "center",
                        backgroundColor: '#f0f3f5',
                        padding: '2px',
                        cursor: 'pointer',
                        border: '1px solid #dddfe1',
                    }}
                >
                    <div>
                        <InputLabel id="advertiser-label">Choose a period</InputLabel>
                    </div>
                    <div>
                        <MonthPickerComponent onMonthChange={handleMonthChange} initialMonthYear={editRow.period}/>
                    </div>
                </div>

                <FormControl fullWidth margin="dense">
                    <InputLabel id="advertiser-label">Advertiser</InputLabel>
                    <Select
                        labelId="advertiser-label"
                        id="advertiser"
                        name="advertiser"
                        value={state.advertiser ?? editRow.advertiserOriginal}
                        onChange={handleInputChange}
                        required={true}
                        label="Advertiser"
                    >
                        {dsp.map((dsp) => (
                            <MenuItem key={dsp.value.id} value={dsp.value.id}>
                                {dsp.value.name} (ID: {dsp.value.id})
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    margin="dense"
                    id="sys_revenue"
                    label="Revenue From The System"
                    type="number"
                    fullWidth
                    name="sys_revenue"
                    value={state.sys_revenue}
                    onChange={handleInputChange}
                    required={true}
                />

                <TextField
                    margin="dense"
                    id="fin_revenue"
                    label="Final Revenue"
                    type="number"
                    fullWidth
                    name="fin_revenue"
                    value={state.fin_revenue}
                    onChange={handleInputChange}
                    required={true}
                />

                <TextField
                    margin="dense"
                    id="file_link"
                    label="File Link"
                    type="text"
                    fullWidth
                    name="file_link"
                    value={state.file_link}
                    onChange={handleInputChange}
                />

                <div>
                    <InputLabel id="advertiser-label">Or choose a file</InputLabel>
                </div>
                <div>
                    <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleInputFileChange}
                    />
                </div>

                <TextField
                    margin="dense"
                    id="comment"
                    label="Revision Comment"
                    type="text"
                    fullWidth
                    name="comment"
                    value={state.comment}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={sendToServer} color="success">
                    Save
                </Button>
            </DialogActions>

            <Dialog
                open={state.confirmationOpen}
                onClose={() => dispatch({ type: 'toggleConfirmation' })}
            >
                <DialogTitle>Confirmation Required</DialogTitle>
                <DialogContent>
                    The difference between The System Revenue and The Final Revenue is more than 50%. Are you sure you want to proceed?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => dispatch({ type: 'toggleConfirmation' })} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={submitData} color="success">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    );
}

export default EditRowDialog;