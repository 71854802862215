import React, {useState, useEffect, useRef} from 'react';
import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    CircularProgress,
    Box,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    Alert,
    AlertTitle,
    Dialog,
} from '@mui/material';
import StatisticsCheckbox from './StatisticsCheckbox';
import SelectMulti from '../../../../components/select/SelectMulti';
import CreateExternalCompanyDialog from '../../../../components/dialog/CreateExternalCompanyDialog';
import useApi from '../../../../hooks/useApi';
import {
    fetchXmlExternalsStatisticFields, fetchAllXmlExternals
} from '../../../../API/DatabaseReport';
import {
    fetchPubManagers, fetchUsersData
} from '../../../../API/AdminUserControl';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import {toast} from 'react-toastify';
import {sendDataToApi} from '../../../../API/apiHelper';

function ExternalsOptionsForm({onSubmit}) {

    const [showCompanies, setShowCompanies] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [showCedato, setShowCedato] = useState(false);

    const [openSend, setOpenSend] = useState(false);
    const [options, setOptions] = useState({
        stats: [],
        filters: [],
        users: [],
        companies: [],
    });

    const [selectedCompaniesFilter, setSelectedCompaniesFilter] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedCedato, setSelectedCedato] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editCompany, setEditCompany] = useState({
        name: '',
        manager_id: '',
        is_premium: '',
        type: '',
        ChangedBy: '',
    });

    const {data: statistics, loading: loadingStats} = useApi(fetchXmlExternalsStatisticFields);
    const {data: companies, loading: loadingCompanies} = useApi(fetchAllXmlExternals);
    const {data: users, loading: loadingUsers} = useApi(fetchUsersData);

    useEffect(() => {
        console.log(companies);
    }, [companies]);
    const openSendSuccess = () => {
        setOpenSend(true);
    };

    const handleCloseSend = () => {
        setOpenSend(false);
    };

    const handleCheckboxChangeStat = (event) => {
        const {name, checked} = event.target;
        let stats = [...options.stats];

        if (checked) {
            stats.push(name);
        } else {
            stats = stats.filter((satat) => satat !== name);
        }

        setOptions({...options, stats});
    };

    const handleSubmit = async (event) => {
        onSubmit(options);
    };

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleInputCreateChange = (event) => {
        const {name, value} = event.target;
        setEditCompany({...editCompany, [name]: value});
    };

    const handleSave = () => {
        const userId = localStorage.getItem('user_id');
        const updatedCompany = {
            ...editCompany,
            ChangedBy: userId,
            type: 'Externals',
        };

        const response = sendDataToApi('post', '/api/database/xml-external-company/create', updatedCompany, false, (data) => {
            setOpen(false);
            setEditCompany({
                name: '',
                manager_id: '',
                is_premium: '',
                type: '',
                ChangedBy: '',
            });
            openSendSuccess();
        });
    };

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            companies: [selectedCompaniesFilter],
        }));
    }, [selectedCompaniesFilter]);

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            users: [selectedUsers],
        }));
    }, [selectedUsers]);

    return (
        <Grid container spacing={2} sx={{width: "100%", marginLeft: '0px'}}>
            <Accordion sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: '#f0f3f5', width: "100%"}}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{width: "100%"}}>
                    <Grid item xs={12} md={12} sx={{width: "100%"}}>
                        <form>
                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '0px 0 25px auto'}}>
                                <Grid item xs={12} md={12}>
                                    {Object.keys(statistics).length != 0 ? (<strong>Data</strong>) : ''}
                                </Grid>
                                {statistics?.map((statistic) => (
                                    <StatisticsCheckbox
                                        key={statistic.key}
                                        statistic={statistic}
                                        checked={options.stats.includes(statistic.key)}
                                        onChange={handleCheckboxChangeStat}
                                    />
                                ))}
                            </Grid>

                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>
                                <Grid item xs={12} md={12}>
                                    <strong>Filters</strong>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        {Object.keys(companies).length != 0 ? (<FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showCompanies}
                                                    onChange={() => setShowCompanies(!showCompanies)}
                                                    name="companies"
                                                />
                                            }
                                            label="Company"
                                        />) : ''}

                                        {Object.keys(users).length != 0 ? (<FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={showUser}
                                                    onChange={() => setShowUser(!showUser)}
                                                    name="User"
                                                />
                                            }
                                            label="User"
                                        />) : ''}
                                    </Grid>

                                    <Grid item xs={6}>
                                        {showCompanies && (
                                            <SelectMulti
                                                label="Company"
                                                items={companies}
                                                selectedItems={selectedCompaniesFilter}
                                                onChange={(event) => setSelectedCompaniesFilter(event.target.value)}
                                                itemLabel="value.name"
                                                itemKey="value.id"
                                                itemValue="value.id"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {showUser && (
                                            <SelectMulti
                                                label="Users"
                                                items={users}
                                                selectedItems={selectedUsers}
                                                onChange={(event) => setSelectedUsers(event.target.value)}
                                                itemLabel="value.name"
                                                itemKey="value.id"
                                                itemValue="value.id"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Grid item xs={6} md={6}>
                <Button
                    size="large"
                    startIcon={<BackupTableOutlinedIcon/>}
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    color="primary"
                    sx={{marginLeft: '-15px'}}
                >
                    Generate Report
                </Button>
                <Button
                    size="large"
                    startIcon={<PostAddOutlinedIcon/>}
                    onClick={handleClickOpen}
                    variant="contained"
                    color="success"
                    sx={{marginLeft: '15px'}}
                >
                    Create Company
                </Button>
            </Grid>
            <CreateExternalCompanyDialog
                open={open}
                handleClose={handleClose}
                editCompany={editCompany}
                handleInputCreateChange={handleInputCreateChange}
                handleSave={handleSave}
                users={users}
            />

            <Dialog open={openSend} onClose={handleCloseSend}>
                <Alert severity="success">
                    <AlertTitle>Success!</AlertTitle>
                    Company <strong>successfully</strong> сreated!
                </Alert>
            </Dialog>
        </Grid>
    );
}

export default ExternalsOptionsForm;