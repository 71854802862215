import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Alert,
    AlertTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress
} from '@mui/material';
import SelectMulti from '../../../../../components/select/SelectMulti';
import {sendJsonToApi} from '../../../../../API/apiHelper';
import {toast} from 'react-toastify';

function UploadFileDialog({
                              open,
                              handleClose,
                              templateLink,
                              onSuccess,
                          }) {

    const [file, setFile] = useState(null);
    const [endpoint, setEndpoint] = useState('/api/financialtools/xml/totalrevenue/csv');
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const handleChange = (event) => {
        setFile(event.target.files[0]);
    };

    const sendFileToServer = () => {
        if (!file) {
            toast.error('Please select a file first!');
            return;
        }

        const userId = localStorage.getItem('user_id');

        const formData = new FormData();
        formData.append('file', file);
        formData.append('user_id', userId);

        axios.post(`${BASE_URL}${endpoint}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                if (response.data.success) {
                    toast.success(response.data.message);
                    setFile(null);
                    onSuccess();
                    handleClose();
                } else {
                    toast.error('Error: ' + response.data.message);
                }
            })
            .catch((error) => {
                toast.error('An error occurred while sending a file:', error.message);
                console.error('An error occurred while sending a file:', error.message);
            });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Upload the CSV file to download data into the system</DialogTitle>
            <DialogContent>
                <Alert variant="outlined" severity="info" color="info" sx={{marginBottom: "20px"}}>
                    <h2>Instructions for Using CSV Template</h2>
                    <li><span>📥</span> <strong>Download the CSV Template:</strong> Click on the provided link to
                        download the example CSV file. This file already includes all the necessary fields.
                    </li>
                    <li><span>📝</span> <strong>Fill in the Data:</strong> Using the downloaded CSV file as a guide,
                        fill in the corresponding information in each field. Ensure accuracy and consistency with
                        the provided example.
                    </li>
                    <li><span>⬆️</span> <strong>Upload Your File:</strong> Once you have completed filling in the
                        data, locate the upload button below these instructions. Click on it to select your filled
                        CSV file from your device.
                    </li>
                    <li><span>✅</span> <strong>Finalize and Submit:</strong> After uploading your file, review the
                        information to confirm its accuracy. Once satisfied, submit the file for processing by
                        button "Save".
                    </li>
                    <p><span>🔍</span> Remember to adhere closely to the provided example to ensure proper formatting and
                        data integrity. Happy filling!</p>

                    <p><span>🚨</span> <strong>
                        <a href={templateLink} download>
                            Click here to download a template!
                        </a>
                    </strong> <span>🚨</span></p>
                </Alert>


                <div>
                    <InputLabel id="advertiser-label">Upload a CSV file with data</InputLabel>
                </div>
                <div>
                    <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleChange}
                    />
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={sendFileToServer} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UploadFileDialog;