import React, {useEffect, useState, useRef} from 'react';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Alert,
    AlertTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import DeleteRowDialog from '../components/DeleteRowDialog';
import {sendDataToApi} from '../../../../API/apiHelper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useApi from '../../../../hooks/useApi';
import {styled} from '@mui/system';
import Cookies from 'js-cookie';

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    '& .MuiDataGrid-row': {
        '&:nth-child(even)': {
            backgroundColor: '#eaf0f5',
        },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },
    '& .negative.win_rate_per, .negative.revenue_per, .negative.fill_rate_per, .negative.profit_per': {
        backgroundColor: 'rgba(255,235,235)',
    },
    '& .positive.win_rate_per, .positive.revenue_per, .positive.fill_rate_per, .positive.profit_per': {
        backgroundColor: 'rgba(235,255,235)',
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal !important',
        wordWrap: 'break-word',
    },
    '& .total-row': {
        backgroundColor: 'lightgrey',
        fontWeight: 'bold',
    },
}));

function processData(data) {
    if (!data || !Array.isArray(data) || data.length === 0) return [];
    return data.map(row => ({
        id: row.value.id,
        ...row.value,
        smarthubDisplay: row.value.smarthub ? row.value.smarthub.map(sh => sh.name).join(", ") : "",
        smarthubOriginal: row.value.smarthub ? row.value.smarthub.map(sh => sh.id) : [],

    }));
}

const AdmyCompaniesTable = ({data, onRowEdit}) => {
    const gridRef = useRef(null);
    const [rows, setRows] = useState(processData(data));
    const [open, setOpen] = useState(false);
    const [openSend, setOpenSend] = useState(false);
    const [editRow, setEditRow] = useState({});
    const [openDelete, setOpenDelete] = useState(false);


    const id = rows.some(row => 'id' in row);
    const name = rows.some(row => 'name' in row);
    const short_name = rows.some(row => 'short_name' in row);
    const compare = rows.some(row => 'compare' in row);
    const api_key = rows.some(row => 'api_key' in row);
    const active = rows.some(row => 'active' in row);
    const type = rows.some(row => 'type' in row);

    useEffect(() => {
        setRows(processData(data));
    }, [data]);

    let columns = [];
    if (id) columns.push({field: 'id', headerName: 'ID', width: 80});
    if (name) columns.push({field: 'name', headerName: 'Company Name', width: 200});
    if (short_name) columns.push({field: 'short_name', headerName: 'Short Name', width: 150});
    if (api_key) columns.push({field: 'api_key', headerName: 'API Key', width: 400});
    if (type) columns.push({field: 'type', headerName: 'Type', width: 100});
    if (active) columns.push({field: 'active', headerName: 'Active', width: 100});
    if (compare) columns.push({field: 'compare', headerName: 'compare', width: 150});

    const userAdmin = Cookies.get('user_admin');
    const userManager = Cookies.get('user_manager');

    columns.push({
        field: 'action',
        headerName: '',
        width: 100,
        renderCell: (params) => {
            const canEdit = (userAdmin === '1' || userManager === '1');
            return (
                <Button
                    variant="outlined"
                    startIcon={<EditIcon/>}
                    color="primary"
                    size="medium"
                    onClick={() => handleButtonClick(params.row)}
                    disabled={!canEdit}
                >
                    Edit
                </Button>
            )
        },
    });

    columns.push({
        field: 'action 2',
        headerName: '',
        width: 130,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
            const canEdit = (userAdmin === '1' || userManager === '1');
            return (
                <Button
                    variant="outlined"
                    startIcon={<DeleteIcon/>}
                    color="error"
                    size="medium"
                    onClick={() => handleDeleteButtonClick(params.row)}
                    disabled={!canEdit}
                >
                    Delete
                </Button>
            );
        },
    });

    const handleButtonClick = (row) => {
        setOpen(true);
        setEditRow({
            ...row,
            smarthub: row.smarthubOriginal,
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSend = () => {
        setOpenSend(false);
    };

    const openSendSuccess = () => {
        setOpenSend(true);
    };

    const handleDeleteButtonClick = (row) => {
        setEditRow(row);
        setOpenDelete(true);
    };

    const handleDeleteClose = () => {
        setOpenDelete(false);
    };

    const handleRowDelete = (deletedRowId) => {
        setRows(rows.filter(row => row.id !== deletedRowId));
    };

    const handleSave = () => {
        const userId = localStorage.getItem('user_id');
        const updatedCompany = {
            ...editRow,
            ChangedBy: userId,
        };


        const response = sendDataToApi('post', '/api/xml/admy-companies/update', updatedCompany, false, (data) => {
            const updatedArray = rows.map(item => {
                if (item.id === editRow.id) {
                    return {...editRow};
                }
                return item;
            });
            setRows(updatedArray);
            setOpen(false);
            openSendSuccess();
            onRowEdit();
        });
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setEditRow({...editRow, [name]: value});
    };

    const handleChangeUser = (id, name) => {
        setEditRow(prev => ({...prev, manager_id: id, manager_name: name}));
    };

    if (data.length === 0) {
        return null;
    } else {

        return (

            <div style={{height: 800, width: '100%'}} ref={gridRef}>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={25}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    disableRowSelectionOnClick
                />

                <Dialog open={openSend} onClose={handleCloseSend}>
                    <Alert severity="success">
                        <AlertTitle>Success</AlertTitle>
                        The Company changed <strong>successfully!</strong>
                    </Alert>
                </Dialog>

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Edit {editRow.name}</DialogTitle>
                    <DialogContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: '400px',
                            justifyContent: 'space-between',
                        }}
                    >
                        <FormControl fullWidth margin="dense">
                            <TextField
                                margin="dense"
                                id="name"
                                label="Company Name"
                                type="text"
                                fullWidth
                                name="name"
                                value={editRow.name}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl fullWidth margin="dense">
                            <TextField
                                margin="dense"
                                id="name"
                                label="Short Name"
                                type="text"
                                fullWidth
                                name="short_name"
                                value={editRow.short_name}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl fullWidth margin="dense">
                            <TextField
                                margin="dense"
                                id="name"
                                label="API Key"
                                type="text"
                                fullWidth
                                name="api_key"
                                value={editRow.api_key}
                                onChange={handleInputChange}
                            />
                        </FormControl>

                        <FormControl fullWidth margin="dense">
                            <InputLabel id="active-label">Active</InputLabel>
                            <Select
                                value={editRow.active}
                                onChange={handleInputChange}
                                name="active"
                                label="Active"
                                fullWidth
                            >
                                <MenuItem value={"1"}>Yes</MenuItem>
                                <MenuItem value={"0"}>No</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth margin="dense">
                            <InputLabel id="compare-label">Compare</InputLabel>
                            <Select
                                value={editRow.compare}
                                onChange={handleInputChange}
                                name="compare"
                                label="Compare"
                                fullWidth
                            >
                                <MenuItem value={"1"}>Yes</MenuItem>
                                <MenuItem value={"0"}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSave}>Save</Button>
                    </DialogActions>
                </Dialog>

                <DeleteRowDialog
                    open={openDelete}
                    handleClose={handleDeleteClose}
                    editRow={editRow}
                    onDeleteSuccess={handleRowDelete}
                />
            </div>
        );
    }
};

export default AdmyCompaniesTable;