import React, { useEffect, useState, useRef } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { styled } from '@mui/system';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-row:nth-child(even)': {
        backgroundColor: '#eaf0f5',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal !important',
        wordWrap: 'break-word',
    },
    '& .total-row': {
        backgroundColor: '#b8ccda',
        fontWeight: 'bold',
    },
}));

function processData(data) {
    if (!data || !Array.isArray(data) || data.length === 0) return [];

    return data.map((row, index) => {
        const rowData = row.value || row;
        return {
            id: rowData.id || index,
            ...rowData
        };
    });
}


const RemFeedBlockReportGrid = ({ data }) => {
    const gridRef = useRef(null);
    const [rows, setRows] = useState([]);

    const availableFields = new Set();

    if (data && Array.isArray(data)) {
        data.forEach((row) => {
            const rowData = row.value || row;
            Object.keys(rowData).forEach((key) => availableFields.add(key));
        });
    }

    useEffect(() => {
        const processedData = processData(data);
        setRows(processedData);
    }, [data]);

    if (!rows.length) return null;

    let columns = [];
    if (availableFields.has('id')) {
        columns.push({
            field: 'id',
            headerName: 'ID',
            flex: 1,
            minWidth: 75,
        });
    }
    if (availableFields.has('feed_id')) {
        columns.push({
            field: 'feed_id',
            headerName: 'Feed ID',
            flex: 2,
            minWidth: 75,
        });
    }
    if (availableFields.has('remotefeed_id')) {
        columns.push({
            field: 'remotefeed_id',
            headerName: 'Remote Feed ID',
            flex: 1,
            minWidth: 220,
        });
    }
    if (availableFields.has('list_id')) {
        columns.push({
            field: 'list_id',
            headerName: 'List ID',
            flex: 1,
            minWidth: 220,
        });
    }
    if (availableFields.has('sub_id')) {
        columns.push({
            field: 'sub_id',
            headerName: 'Blocked SubIDs',
            flex: 1,
            minWidth: 200,
        });
    }
    if (availableFields.has('list_type')) {
        columns.push({
            field: 'list_type',
            headerName: 'List Type',
            flex: 1,
            minWidth: 100,
        });
    }
    if (availableFields.has('date')) {
        columns.push({
            field: 'date',
            headerName: 'Date',
            flex: 1,
            minWidth: 150,
        });
    }

    return (
        <div style={{ height: 800, width: '100%' }} ref={gridRef}>
            <StyledDataGrid
                rows={rows}
                columns={columns}
                pageSize={25}
                slots={{
                    toolbar: GridToolbar,
                }}
                disableRowSelectionOnClick
            />
        </div>
    );
};

export default RemFeedBlockReportGrid;
