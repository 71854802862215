import React, {useState, useEffect} from 'react';
import {Box, Button, Grid, Typography, CircularProgress} from "@mui/material";
import DownloadButton from "../../../components/reports/DownloadButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AdvertisersOptionsForm from "./options/AdvertisersOptionsForm";
import {sendDataToApi} from '../../../API/apiHelper';
import OutletIcon from "@mui/icons-material/Outlet";
import AdvertisersDataGrid from "./components/AdvertisersDataGrid";
import Accordion from '@mui/material/Accordion';
import {toast} from 'react-toastify';
import { format } from 'date-fns';
import "@fontsource/roboto";

const Advertisers = () => {

    const [data, setData] = useState([]);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [cacheOptions, setCacheOptions] = useState('');

    useEffect(()=>{
        const currentDate = new Date();
        const formattedDate = format(currentDate, 'yyyy-MM-dd');
        setName( `Advertisers - ${formattedDate}` );
    },[data]);

    const handleSubmit = async (options) => {
        try {
            if(options){
                setCacheOptions(options);
            }
            setLoading(true);
            const response = await sendDataToApi('post', '/api/database/advertiser/report', options, 'advertiser');
            if (Object.keys(response.data).length === 0) {
                setData(false);
            } else {
                const fieldsArray = Object.entries(response.data).map(([key, value]) => ({key, value}));
                setData(fieldsArray);
            }
            setLoading(false);
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            console.error(err);
            setLoading(false);
        }
    };

    const handleRowEdit = (newData) => {
        handleSubmit(cacheOptions);
    };

    return (
        <div>
            <Grid container spacing={2}
                  sx={{
                      padding: 2,
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      border: '4px',
                      paddingLeft: 0,
                      paddingTop: 0,
                      marginLeft: '-10px',
                      marginTop: '-5px',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                  }}
            >
                <Grid item xs={12} md={12} sx={{width: "100%"}}>
                    <Accordion sx={{width: "100%"}}>
                        <AccordionSummary
                            expandIcon={<InfoOutlinedIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                backgroundColor: '#f0f3f5',
                                width: "100%",
                                height: '35px !important',
                                minHeight: '25px !important',
                            }}
                        >
                            Explanations
                        </AccordionSummary>
                        <AccordionDetails sx={{width: "100%"}}>
                            <Box>
                                <Typography variant="subtitle1" sx={{ marginBottom: '1rem' }}>
                                    📊 Report Options and Functionalities
                                </Typography>

                                <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>
                                    📈 Statistics Selection:
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
                                    Similar to data selection, you can also select specific statistics fields for the report by checking the checkboxes provided.
                                    The available statistics fields are also fetched dynamically.
                                    The selected statistics fields will be included in the generated report.
                                </Typography>

                                <Typography variant="subtitle1" sx={{ marginBottom: '0.5rem' }}>
                                    🚀 Generate Report:
                                </Typography>
                                <Typography variant="body1" sx={{ marginBottom: '1rem', marginLeft: '1rem' }}>
                                    Once you have made the desired selections and configurations, you can click the "Generate Report" button.
                                    The report generation process involves making API requests and fetching the required data based on the selected options.
                                </Typography>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>

            <Grid container spacing={2}
                  sx={{
                      padding:2,
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      border: '4px',
                      paddingLeft:0,
                      marginLeft: '-10px',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.08)',
                      marginTop: '20px',
                  }}
            >
                <Grid item xs={12} md={12} sx={{ width: "100%" }}>
                    <AdvertisersOptionsForm onSubmit={handleSubmit} />
                </Grid>
                <Grid item xs={12} md={12}>
                    {data.length != 0 || data != false && <DownloadButton
                        data={data}
                        label={name}
                        sx={{float: 'right',}}
                    />}
                </Grid>
                <Grid item xs={12} md={12} >
                    {
                        loading &&
                        <Grid item xs={12} md={12}>
                            <Box justifyContent="center" textAlign={"center"} alignItems="center">
                                <CircularProgress size={200}/>
                            </Box>
                        </Grid>
                    }
                    {data === false ? (
                        <Typography variant="h4" component="h2" align="center"><OutletIcon
                            fontSize="large"/> NO DATA </Typography>
                    ) : (
                        <AdvertisersDataGrid data={data} onRowEdit={handleRowEdit}/>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default Advertisers;