import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";
import {
    Button,
    Grid,
    Autocomplete,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    Box,
    InputLabel,
    Radio,
    Alert,
    AlertTitle,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Select,
    MenuItem,
    Chip,
    FormControl,
    OutlinedInput,
    InputAdornment,
    FormHelperText,
} from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import {
    usersFields
} from '../../../../API/AdminUserControl';
import {sendDataToApi} from '../../../../API/apiHelper';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import Cookies from 'js-cookie';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import {toast} from 'react-toastify';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};

function INAPPOptionsForm({onGenerate}) {

    const [options, setOptions] = useState({
        stats: [],
        status: [],
        role: [],
    });

    const [statistics, setStatistics] = useState([]);
    const [ip, setSelectedIp] = useState([]);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [editUser, setEditUser] = useState({
        Name: '',
        Email: '',
        password: '',
        Status: '',
        Position: '',
        Role: [],
        Project: [],
        Ip: [],
        ChangedBy: '',
    });

    const [validations, setValidations] = useState({
        Status: false,
        Email: false,
        password: false
    });

    const [openSend, setOpenSend] = useState(false);

    const [selectedRole, setSelectedRole] = useState([]);

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            role: [selectedRole],
        }));
    }, [selectedRole]);


    const [selectedStatus, setSelectedStatus] = useState([]);

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            status: [selectedStatus],
        }));
    }, [selectedStatus]);

    useEffect(() => {
        const fetchStatistics = async () => {
            const stats = await usersFields();
            setStatistics(stats);

        };

        const firstRun = async () => {
            try {
                setLoading(true);
                const response = await sendDataToApi('post', '/api/user/all', options);
                const fieldsArray = Object.entries(response.data).map(([key, value]) => ({key, value}));
                setLoading(false);
                onGenerate(fieldsArray);
            } catch (err) {
                toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
                setLoading(false);
                console.error(err);
                return [];
            }
        };

        try {
            firstRun();
            fetchStatistics();
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            console.error(err);
        }
    }, []);

    useEffect(() => {
        const storedValue = Cookies.get('userControl');
        if (storedValue) {
            const parsedValue = JSON.parse(storedValue);

            if ('stats' in parsedValue) {
                setOptions(prevOptions => ({
                    ...prevOptions,
                    stats: parsedValue.stats
                }));
            }

            if ('role' in parsedValue) {
                setSelectedRole(parsedValue.role[0])
            }

            if ('status' in parsedValue) {
                setSelectedStatus(parsedValue.status[0]);
            }
        }
    }, []);

    const handleSubmit = async (event) => {
        try {
            setLoading(true);
            const response = await sendDataToApi('post', '/api/user/all', options, 'userControl');
            const fieldsArray = Object.entries(response.data).map(([key, value]) => ({key, value}));
            setLoading(false);
            onGenerate(fieldsArray);
        } catch (err) {
            toast.error(`Oops, there seems to be an error on the server. The technical team has already received information about this. We apologize for the inconvenience and we will let you know when it is fixed.`);
            setLoading(false);
            console.error(err);
            return [];
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setEditUser({...editUser, [name]: value});

        if (name === 'Email') {
            const isValidEmail = validateEmail(value);
            setValidations((prevValidations) => ({
                ...prevValidations,
                Email: isValidEmail
            }));
        } else if (name === 'password') {
            const isValidPassword = value.length >= 6;
            setValidations((prevValidations) => ({
                ...prevValidations,
                password: isValidPassword
            }));
        } else if (name === 'Status') {
            const isValidPassword = value;
            setValidations((prevValidations) => ({
                ...prevValidations,
                Status: isValidPassword
            }));
        }
    };


    const handleCheckboxChange = (event) => {
        const {name} = event.target;
        setOptions({...options, fields: name});
    };

    const handleChangeStatus = (event) => {
        setSelectedStatus(event.target.value);
    };

    const handleChangeRole = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleCheckboxChangeStat = (event) => {
        const {name, checked} = event.target;
        let stats = [...options.stats];
        if (checked) {
            stats.push(name);
        } else {
            stats = stats.filter((satat) => satat !== name);
        }
        setOptions({...options, stats});
    };


    const handleSave = () => {
        const userId = localStorage.getItem('user_id');
        const updatedUser = {
            ...editUser,
            Ip: ip,
            ChangedBy: userId
        };
        const response = sendDataToApi('post', '/api/user/create', updatedUser, false, (data) => {
            //handleSubmit();
            setOpen(false);
            setEditUser({
                Name: '',
                Email: '',
                password: '',
                Status: '',
                Position: '',
                Role: [],
                Ip: [],
                ChangedBy: '',
            });
            openSendSuccess();
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openSendSuccess = () => {
        setOpenSend(true);
    };

    const handleCloseSend = () => {
        setOpenSend(false);
    };

    const handleChange = (event, newValue) => {
        setSelectedIp(newValue);
    };

    return (
        <Grid container spacing={2} sx={{width: "100%", marginLeft: '0px',}}>
            <Accordion sx={{width: "100%"}}>
                <AccordionSummary
                    expandIcon={<KeyboardDoubleArrowDownOutlinedIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: '#f0f3f5', width: "100%"}}
                >
                    Options
                </AccordionSummary>
                <AccordionDetails sx={{width: "100%"}}>
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12} md={12} sx={{width: "100%"}}>
                            <Grid item xs={12} md={12} sx={{width: "100%", margin: '25px 0 25px auto'}}>

                                <Grid item xs={12} md={12}>
                                    {Object.keys(statistics).length != 0 ? (<strong>Data</strong>) : ''}
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    {statistics.map((statistic, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    checked={options.stats.includes(statistic.key)}
                                                    onChange={handleCheckboxChangeStat}
                                                    name={statistic.key}
                                                />
                                            }
                                            label={statistic.value}
                                        />
                                    ))}
                                </Grid>

                                <Grid item xs={12} md={12} sx={{width: "100%", margin: '10px 0 10px auto'}}>
                                    <strong>Filters</strong>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <FormControl sx={{m: 1, width: 350}}>
                                        <InputLabel id="demo-multiple-chip-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-multiple-chip-label"
                                            id="demo-multiple-chip"
                                            multiple
                                            value={selectedStatus}
                                            onChange={handleChangeStatus}
                                            input={<OutlinedInput id="select-multiple-chip" label="Object type"/>}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem key='active' value='active'>
                                                Active
                                            </MenuItem>
                                            <MenuItem key='blocked' value='blocked'>
                                                Blocked
                                            </MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{m: 1, width: 350}}>
                                        <InputLabel id="demo-multiple-chip-label">Role</InputLabel>
                                        <Select
                                            labelId="demo-multiple-chip-label"
                                            id="demo-multiple-chip"
                                            multiple
                                            value={selectedRole}
                                            onChange={handleChangeRole}
                                            input={<OutlinedInput id="select-multiple-chip" label="Object type"/>}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem key='admin' value='admin'>
                                                Admin
                                            </MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>

                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Grid item xs={6} md={6}>
                <Button
                    size={"large"}
                    startIcon={<BackupTableOutlinedIcon fontSize="small"/>}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{marginLeft: '-15px'}}
                >
                    Show Users
                </Button>


                <Button
                    size={"large"}
                    startIcon={<GroupAddIcon fontSize="small"/>}
                    onClick={handleClickOpen}
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{marginLeft: '15px'}}
                >
                    Create User
                </Button>
            </Grid>
            {loading &&
                <Grid item xs={12} md={12}>
                    <Box justifyContent="center" textAlign={"center"} alignItems="center">
                        <CircularProgress size={200}/>
                    </Box>
                </Grid>}

            <Dialog
                open={open}
                onClose={handleClose}

            >
                <DialogTitle>Create а User</DialogTitle>

                <DialogContent>
                    <Alert variant="outlined" severity="info" color="info" sx={{marginBottom: "20px"}}>
                        Password should be not less than <strong>6 symbols</strong><br/>
                        Email should be <strong>correct</strong><br/>
                        Status should be <strong>selected</strong>
                    </Alert>

                    <TextField
                        autoFocus
                        margin="dense"
                        name="Name"
                        label="User Name"
                        type="text"
                        value={editUser.Name}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        name="Email"
                        label="User Email"
                        type="Email"
                        value={editUser.Email}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        error={!validations.Email}
                    />
                    <TextField
                        margin="dense"
                        name="password"
                        label="Password"
                        type="password"
                        value={editUser.password}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        error={!validations.password}
                    />

                    <FormControl fullWidth required margin="dense">
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                            value={editUser.Status}
                            onChange={handleInputChange}
                            name="Status"
                            label="Status"
                            fullWidth
                            required
                            error={!validations.Status}
                        >
                            <MenuItem value={"active"}>Active</MenuItem>
                            <MenuItem value={"blocked"}>Blocked</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="dense">
                        <InputLabel id="project-label">Project</InputLabel>
                        <Select
                            labelId="project-label"
                            label="Project"
                            multiple
                            value={Array.isArray(editUser.Project) ? editUser.Project : []}
                            onChange={(event) => {
                                setEditUser({...editUser, Project: event.target.value});
                            }}
                            name="Project"
                            renderValue={(selected) => (
                                <div>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </div>
                            )}
                        >
                            <MenuItem value={"iRTB"}>iRTB</MenuItem>
                            <MenuItem value={"XML"}>XML</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="dense">
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                            labelId="role-label"
                            label="Role"
                            multiple
                            value={editUser.Role}
                            onChange={handleInputChange}
                            name="Role"
                            renderValue={(selected) => (
                                <div>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value}/>
                                    ))}
                                </div>
                            )}
                        >
                            <MenuItem value={"account manager"}>Account Manager</MenuItem>
                            <MenuItem value={"publisher manager"}>Publisher Manager</MenuItem>
                            <MenuItem value={"financial manager"}>Financial Manager</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="dense">
                        <TextField
                            margin="dense"
                            name="Position"
                            label="User Position"
                            type="text"
                            value={editUser.Position}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </FormControl>

                    <FormControl fullWidth margin="dense">
                        <Autocomplete
                            multiple
                            freeSolo
                            name="Ip"
                            options={[]}
                            value={ip}
                            onChange={handleChange}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({index})} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Allowed IPs"
                                    placeholder="Add an item and press Enter"
                                />
                            )}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave}
                            disabled={!validations.Email || !validations.password || !validations.Status}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openSend} onClose={handleCloseSend}>
                <Alert severity="success">
                    <AlertTitle>Success!</AlertTitle>
                    User сreated <strong>successfully!</strong>
                </Alert>
            </Dialog>
        </Grid>

    );
}

export default INAPPOptionsForm;
